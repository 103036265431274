import React, { useState } from "react";
import styled from "styled-components";
import Layout from "../components/layout";
import { NavBar } from "../components/nav-bar";
import { graphql } from "gatsby";
import Modal from "@material-ui/core/Modal";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import BoxImg from "../images/bigdata/box.png";
import Logo from "../images/bigdata/logo.png";
import Player from "../images/player.png";
import { Footer } from "../components/Footer/index";
import { useTranslation } from "gatsby-plugin-react-i18next";
const CardList = [
  {
    title: "投资机会",
    entitle: "Feasibility Study",
    list: ["商业板块价值分析", "城市更新机会扫描", "板块核心指标排名", "自定义区域数据组合", "商圈项目穿透分析"],
    video: "https://amssdata-citymap.oss-cn-beijing.aliyuncs.com/video(2).MP4",
  },
  {
    title: "资产管理",
    entitle: "Asset Management",
    list: ["存量资产战略规划", "资产交易活跃排名", "资产估值分析", "资产风险量化", "Cap Rate计算"],
    video: "https://amssdata-citymap.oss-cn-beijing.aliyuncs.com/video(1).MP4",
  },
  {
    title: "应用工具",
    entitle: "Cash Flow Forecast",
    list: ["一键可研报告", "一键估值与分析", "一键IRR计算", "一键现金流预测", "一键强排建模 "],
    video: "https://amssdata-citymap.oss-cn-beijing.aliyuncs.com/instruction_video.mp4",
  },
];
export default () => {
  const [video, setVideo] = useState(null);
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <Main>
        <NavBar main style={{ backgroundColor: i18n.language === "en" ? "#fff" : "rgb(250, 250,250)" }} />
        <Modal
          open={!!video}
          onClose={() => setVideo(null)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description">
          <Video>
            <video autoPlay loop width="960">
              <source src={video} type="video/mp4" />
            </video>
          </Video>
        </Modal>
        <FirstSection
          isEn={i18n.language === "en"}
          bg={
            i18n.language === "en"
              ? "https://amssdata-citymap.oss-cn-beijing.aliyuncs.com/bg2.gif"
              : "https://amssdata-citymap.oss-cn-beijing.aliyuncs.com/bg.gif"
          }>
          <Container className="container">
            <Grid container>
              <Grid item xs={6}>
                {i18n.language === "zh" ? (
                  <>
                    <Box display="flex" justifyContent="center">
                      <img src={Logo} alt="logo" />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                      <Title>更快、更准的新一代金融地产大数据产品</Title>
                      <SubTitle>
                        <strong>大数据</strong>与<strong>投资测算BI引擎</strong>
                        完美对接：
                        <strong>分析、预测 </strong>
                        <br /> 在地产与金融领域轻松
                        <strong>跨界</strong>
                      </SubTitle>
                    </Box>
                    <Grid container spacing={4}>
                      {CardList.map(card => (
                        <Grid key={card.title} item xs={4}>
                          <Card>
                            <h3 className="title">{card.title}</h3>
                            {card.list.map(li => (
                              <div key={li} className="list">
                                <img className="box" src={BoxImg} alt="box" />
                                {li}
                              </div>
                            ))}
                            <p
                              className="video-button"
                              onClick={() => {
                                if (card.video) {
                                  setVideo(card.video);
                                } else {
                                  alert("视频上线中，敬请期待");
                                }
                              }}>
                              <PlayArrowIcon />
                              观看视频
                            </p>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box>
                      <div className="en-content">
                        Thanks to the extraordinary data quality in China, more and more investors and developers are
                        planning the AI solutions for deal sourcing, investment decision and asset management. AMSS is
                        the first asset management solution integrating the Big Data with BI digital platform.
                      </div>
                    </Box>
                    <Grid container spacing={4} style={{ marginTop: "30px" }}>
                      {CardList.map(card => (
                        <Grid key={card.title} item xs={4}>
                          <EnCard>
                            <h3 className="title">{card.entitle}</h3>
                            <img
                              onClick={() => {
                                if (card.video) {
                                  setVideo(card.video);
                                } else {
                                  alert("视频上线中，敬请期待");
                                }
                              }}
                              style={{ marginTop: "40px" }}
                              width={43}
                              height={43}
                              src={Player}
                              alt=""
                            />
                          </EnCard>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <Box style={{ marginTop: "45px" }} display="flex" justifyContent="center">
              <Button onClick={() => window.open("https://amssdata.com.cn/quicksearch")}>{t("enter")}</Button>
            </Box>
          </Container>
        </FirstSection>
      </Main>
      <Footer />
    </Layout>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { ns: { in: ["index"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const Main = styled.div`
  min-width: 1439px;
  .en-content {
    font-size: 24px;
    line-height: 1.8em;
    color: #35383b;
  }
`;

const FirstSection = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  background-image: ${props => `url(${props.bg})`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 80px 0;
  @media (min-width: 1600px) {
    background-size: cover;
    padding: 100px;
  }
  @media (min-width: 1800px) {
    background-size: cover;
    padding: 150px;
  }
  @media (min-width: 1900px) {
    background-size: cover;
    padding: 200px;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  text-align: center;
  line-height: 3em;
  color: #3a5986;
  font-weight: normal;
  white-space: nowrap;
`;

const SubTitle = styled.h2`
  text-align: center;
  line-height: 2em;
  font-size: 24px;
  font-weight: 500;
  color: #3a5986;
  strong {
    font-size: 30px;
    font-weight: bold;
  }
`;

const Video = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  .video {
    width: 600px;
  }
`;

const Button = styled.div`
  width: 173px;
  height: 45px;
  border: none;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 0px;
  font-weight: normal;
  cursor: pointer;
  border-radius: 4px;
  background-color: rgb(58, 89, 134);
  color: rgb(255, 255, 255);
  font-size: 15px;
  transition: all 300ms ease 0s;
  &:hover {
    background-color: rgb(100, 151, 225);
  }
`;

const Card = styled.div`
  width: 100%;
  margin-top: 20px;
  border-radius: 2px;
  background: rgba(255, 255, 255, 0.85);
  padding: 10px;
  color: ${props => props.theme.mainColor};
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  .title {
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    text-align: center;
    color: #3a5986;
    margin-bottom: 12px;
  }
  .list {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 10px 0;
    word-break: keep-all;
    .box {
      margin-right: 10px;
    }
  }
  .video-button {
    color: #6497e1;
    margin: 10px 0;
    cursor: pointer;
    display: flex;
    font-size: 16px;
    align-items: center;
  }
`;

const EnCard = styled.div`
  width: 200px;
  height: 240px;
  display: flex;
  background-color: #3a5986;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 5px;
  .title {
    color: #fff;
    font-size: 28px;
    text-align: center;
  }
`;
